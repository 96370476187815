import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "../components/gid.sass";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Viewing from "../components/viewing";
import ReviewPopup from "../components/reviewPopup";
// import ImagePopup from "../components/singleImage";
import Fancybox from "../components/fancybox.js";

// Patternss
import Compas from "../images/icons/compas.svg";
import Zoom from "../images/icons/zoom.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Thumbs, FreeMode, EffectFade, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Thumbs, FreeMode, EffectFade, Navigation]);

export default function Card({
  data: { page, info, cottages, contacts, forms, header, footer, socials, copyright, user, cookies },
}) {
  const wpContent = page;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const locale = page.locale.locale;
  let infoContent;
  let cottageContent;
  let formContent;
  let contactContent;

  for (let i = 0; i < info.nodes.length; i++) {
    if (info.nodes[i].locale.locale === locale) {
      infoContent = info.nodes[i].blockPurchaseInfo;
    }
  }

  for (let i = 0; i < cottages.nodes.length; i++) {
    if (cottages.nodes[i].locale.locale === locale) {
      cottageContent = cottages.nodes[i].blockCotteges;
    }
  }

  for (let i = 0; i < forms.nodes.length; i++) {
    if (forms.nodes[i].locale.locale === locale) {
      formContent = forms.nodes[i];
    }
  }

  for (let i = 0; i < contacts.nodes.length; i++) {
    if (contacts.nodes[i].locale.locale === locale) {
      contactContent = contacts.nodes[i];
    }
  }

  return (
    <Layout
      footer={footer.nodes}
      header={header.nodes}
      forms={formContent}
      user={user.nodes}
      cookies={cookies.nodes}
      copyright={copyright.nodes}
      socials={socials.nodes[0].socialNetworks}
      locale={page.locale.locale}
      uri={page.uri}
    >
      <SEO title={wpContent.seo.title} description={wpContent.seo.metaDesc} />
      <nav className="breadcrumb productName" aria-label="Breadcrumb">
        {locale === "ru" && (
          <ol className="breadcrumb__list">
            <li className="breadcrumb__item">
              <Link
                activeClassName="breadcrumb__link__active"
                className="breadcrumb__link"
                to="/ru/"
              >
                Главная
              </Link>
            </li>
            <li className="breadcrumb__item">
              <Link
                activeClassName="breadcrumb__link__active"
                className="breadcrumb__link"
                to="/ru/cottages/"
              >
                Коттеджи
              </Link>
            </li>
            <li className="breadcrumb__item">
              <Link
                activeClassName="breadcrumb__link__active"
                className="breadcrumb__link"
                to={`/ru${page.seo.breadcrumbs[2].url}`}
                dangerouslySetInnerHTML={{
                  __html: page.seo.breadcrumbs[2].text,
                }}
              ></Link>
            </li>
          </ol>
        )}
        {locale === "uk" && (
          <ol className="breadcrumb__list">
            {wpContent.seo.breadcrumbs.map((value, i) => (
              <li key={i} className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to={value.url}
                  // to={value.url}
                  dangerouslySetInnerHTML={{
                    __html: value.text,
                  }}
                ></Link>
              </li>
            ))}
          </ol>
        )}
      </nav>
      <div className="product">
        <div>
          <Swiper
            className="productGall"
            thumbs={{ swiper: thumbsSwiper }}
            speed={500}
            effect={"fade"}
          >
            {wpContent.singleProjectContent.cottageGallery.map((value, i) => (
              <SwiperSlide key={i}>
                <Fancybox>
                  <div
                    className="fancyboxFrame"
                    data-fancybox="gallery"
                    data-src={value.sourceUrl}
                  >
                    <GatsbyImage
                      image={value.localFile.childImageSharp.gatsbyImageData}
                      alt={value.altText || ``}
                    />
                  </div>
                </Fancybox>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            className="productThumbs"
            speed={500}
            slidesPerView={4}
            spaceBetween={10}
            loopAdditionalSlides={4}
            slideToClickedSlide={true}
            onSwiper={setThumbsSwiper}
            freeMode={true}
            allowTouchMove={false}
            watchSlidesProgress={true}
            breakpoints={{
              1300: {
                slidesPerView: 5,
              },
            }}
          >
            {wpContent.singleProjectContent.cottageGallery.map((value, i) => (
              <SwiperSlide key={i}>
                <GatsbyImage
                  image={value.localFile.childImageSharp.gatsbyImageData}
                  alt={value.altText || ``}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="productDetails mainStyle">
          <h1>{wpContent.title}</h1>
          <div className="tags">
            {wpContent.cottagesTerms.nodes.map((item, i) => (
              <span
                className={`${item.slug === "plan" ? "outline" : ""}`}
                key={i}
              >
                {item.name}
              </span>
            ))}
          </div>
          <span className="price">
            {wpContent.singleProjectContent.cottagePrice} $
          </span>
          <div className="cottage__details">
            <div>
              <div
                className="ico"
                dangerouslySetInnerHTML={{
                  __html: cottageContent.cottegeTotalAreaIcon,
                }}
              ></div>
              <span>{cottageContent.cottegeTotalArea}</span>
              <b>{wpContent.singleProjectContent.cottageTotalArea}</b>
            </div>
            <div>
              <div
                className="ico"
                dangerouslySetInnerHTML={{
                  __html: cottageContent.cottegeLevelsIcon,
                }}
              ></div>
              <span>{cottageContent.cottegeLevels}</span>
              <b>{wpContent.singleProjectContent.cottageNumberOfFloors}</b>
            </div>
            <div>
              <div
                className="ico"
                dangerouslySetInnerHTML={{
                  __html: cottageContent.cottegeTotalCarsIcon,
                }}
              ></div>

              <span>{cottageContent.cottegeTotalCars}</span>
              <b>{wpContent.singleProjectContent.cottageNumberOfRooms}</b>
            </div>
            <div>
              <div
                className="ico"
                dangerouslySetInnerHTML={{
                  __html: cottageContent.cottegeLivingAreaIcon,
                }}
              ></div>
              <span>{cottageContent.cottegeLivingArea}</span>
              <b>{wpContent.singleProjectContent.cottagePlotArea}</b>
            </div>
          </div>
        </div>
        <div className="productDetails">
          <div className="productDescription">
            <div>
              <h3>{wpContent.singleProjectContent.aboutCottageTitle}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: wpContent.singleProjectContent.aboutCottageText,
                }}
              ></div>
            </div>
            <div>
              <h3>{wpContent.singleProjectContent.aboutPriceTitle}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: wpContent.singleProjectContent.aboutPriceText,
                }}
              ></div>
            </div>
          </div>
          <ReviewPopup
            buttonIcon={wpContent.singleProjectContent.previewButton.icon}
            buttonText={wpContent.singleProjectContent.previewButton.text}
            forms={formContent}
          />
        </div>
      </div>
      <div className="cardBlock cardInfo">
        <Tabs defaultIndex={2}>
          <header className="tabsHead switchers">
            <TabList>
              <Tab className="switcher">
                {wpContent.singleProjectContent.toggleButton2d}
              </Tab>
              {!!wpContent.singleProjectContent.toggleButton3d && (
                <Tab className="switcher">3d</Tab>
              )}
            </TabList>
            <h5>{wpContent.singleProjectContent.housePlanHeader}</h5>
          </header>
          <TabPanel>
            <div
              className="fancyboxFrame"
              data-fancybox="housePlan2dImgMobile"
              data-src={wpContent.singleProjectContent.housePlan2dImg.sourceUrl}
            >
              <GatsbyImage
                image={
                  wpContent.singleProjectContent.housePlan2dImg.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={wpContent.singleProjectContent.housePlan2dImg.altText || ``}
              />
            </div>
            {/* <ImagePopup imgZoom={wpContent.singleProjectContent.housePlan2dImg} /> */}
            <Fancybox options={{ infinite: false }}>
              <button
                className="zoomButton"
                data-fancybox="housePlan2dImg"
                data-src={wpContent.singleProjectContent.housePlan2dImg.sourceUrl}
              >
                <Zoom />
              </button>
            </Fancybox>
          </TabPanel>
          {!!wpContent.singleProjectContent.toggleButton3d && (
            <TabPanel>
              <div
                className="fancyboxFrame"
                data-fancybox="housePlan3dImgMobile"
                data-src={wpContent.singleProjectContent.housePlan3dImg.sourceUrl}
              >
                <GatsbyImage
                  image={
                    wpContent.singleProjectContent.housePlan3dImg.localFile
                      .childImageSharp.gatsbyImageData
                  }
                  alt={
                    wpContent.singleProjectContent.housePlan3dImg.altText || ``
                  }
                />
              </div>
              {/* <ImagePopup imgZoom={wpContent.singleProjectContent.housePlan3dImg} /> */}
              <Fancybox options={{ infinite: false }}>
                <button
                  className="zoomButton"
                  data-fancybox="housePlan3dImg"
                  data-src={wpContent.singleProjectContent.housePlan3dImg.sourceUrl}
                >
                  <Zoom />
                </button>
              </Fancybox>
            </TabPanel>
          )}
          <Compas className="compass" />
        </Tabs>
        <div>
          <dl>
            <dt>{wpContent.singleProjectContent.firstFloorLabel}</dt>
            <dd>{wpContent.singleProjectContent.firstFloor}</dd>
          </dl>
          <ul>
            {wpContent.singleProjectContent.firstFloorItems.map((value, i) => (
              <li key={i}>
                <strong>{value.itemName}</strong>
                <span>{value.itemValue}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {!!wpContent.singleProjectContent.secondFloor && (
        <div className="cardBlock cardInfo">
          <Tabs defaultIndex={2}>
            <header className="tabsHead switchers">
              <TabList>
                <Tab className="switcher">
                  {wpContent.singleProjectContent.toggleButton2d}
                </Tab>
                {!!wpContent.singleProjectContent.toggleButton3d && (
                  <Tab className="switcher">3d</Tab>
                )}
              </TabList>
              <h5>{wpContent.singleProjectContent.housePlanHeader}</h5>
            </header>
            <TabPanel>
              <div
                className="fancyboxFrame"
                data-fancybox="secondHousePlan2dImgMobile"
                data-src={wpContent.singleProjectContent.secondHousePlan2dImg.sourceUrl}
              >
                <GatsbyImage
                  image={
                    wpContent.singleProjectContent.secondHousePlan2dImg.localFile
                      .childImageSharp.gatsbyImageData
                  }
                  alt={
                    wpContent.singleProjectContent.secondHousePlan2dImg.altText ||
                    ``
                  }
                />
              </div>
              {/* <ImagePopup imgZoom={wpContent.singleProjectContent.secondHousePlan2dImg} /> */}
              <Fancybox options={{ infinite: false }}>
                <button
                  className="zoomButton"
                  data-fancybox="secondHousePlan2dImg"
                  data-src={wpContent.singleProjectContent.secondHousePlan2dImg.sourceUrl}
                >
                  <Zoom />
                </button>
              </Fancybox>
            </TabPanel>
            {!!wpContent.singleProjectContent.toggleButton3d && (
              <TabPanel>
                <div
                  className="fancyboxFrame"
                  data-fancybox="secondHousePlan3dImgMobile"
                  data-src={wpContent.singleProjectContent.secondHousePlan3dImg.sourceUrl}
                >
                  <GatsbyImage
                    image={
                      wpContent.singleProjectContent.secondHousePlan3dImg
                        .localFile.childImageSharp.gatsbyImageData
                    }
                    alt={
                      wpContent.singleProjectContent.secondHousePlan3dImg
                        .altText || ``
                    }
                  />
                </div>
                {/* <ImagePopup imgZoom={wpContent.singleProjectContent.secondHousePlan3dImg} /> */}
                <Fancybox options={{ infinite: false }}>
                  <button
                    className="zoomButton"
                    data-fancybox="secondHousePlan3dImg"
                    data-src={wpContent.singleProjectContent.secondHousePlan3dImg.sourceUrl}
                  >
                    <Zoom />
                  </button>
                </Fancybox>
              </TabPanel>
            )}
          </Tabs>
          <div>
            <dl>
              <dt>{wpContent.singleProjectContent.secondFloorLabel}</dt>
              <dd>{wpContent.singleProjectContent.secondFloor}</dd>
            </dl>
            <ul>
              {wpContent.singleProjectContent.secondFloorItems.map(
                (value, i) => (
                  <li key={i}>
                    <strong>{value.itemName}</strong>
                    <span>{value.itemValue}</span>
                  </li>
                )
              )}
            </ul>
          </div>
          <Compas />
        </div>
      )}
      <div className="cardBlock">
        <span className="switcher active">
          {wpContent.singleProjectContent.masterPlanHeader}
        </span>
        {/* <ImagePopup imgZoom={wpContent.singleProjectContent.masterPlanImg} /> */}
        <Fancybox options={{ infinite: false }}>
          <button
            className="zoomButton"
            data-fancybox="masterPlanImg"
            data-src={wpContent.singleProjectContent.masterPlanImg.sourceUrl}
          >
            <Zoom />
          </button>
        </Fancybox>
        <Compas />
        <div
          className="fancyboxFrame"
          data-fancybox="masterPlanImgMobile"
          data-src={wpContent.singleProjectContent.masterPlanImg.sourceUrl}
        >
          <GatsbyImage
            image={
              wpContent.singleProjectContent.masterPlanImg.localFile
                .childImageSharp.gatsbyImageData
            }
            alt={wpContent.singleProjectContent.masterPlanImg.altText || ``}
          />
        </div>
      </div>
      <h2>{infoContent.purchaseProcessHeader}</h2>
      <div className="docs gidBox four">
        <ul className="roadmap">
          {infoContent.purchaseStepList.map((value, i) => (
            <li key={i} className="roadmap-item">
              <div className="roadmap-desc">{value.stepItemName}</div>
            </li>
          ))}
        </ul>
      </div>
      <h2>{infoContent.purchaseTermsHeader}</h2>
      <div className="conditions">
        {infoContent.purchaseTermList.map((value, i) => (
          <div key={i}>
            <strong>{value.termItemTitle}</strong>
            <p>{value.termItemDescription}</p>
          </div>
        ))}
      </div>
      <h2>{infoContent.purchaseDocumentsHeader}</h2>
      <div className="docs gidBox">
        <ul className="roadmap">
          {infoContent.purchaseDocumentList.map((value, i) => (
            <li key={i} className="roadmap-item">
              <div className="roadmap-desc">{value.documentItemName}</div>
            </li>
          ))}
        </ul>
        <div
          className="textContent"
          dangerouslySetInnerHTML={{
            __html: infoContent.purchaseAdditionalTextInfo,
          }}
        ></div>
      </div>
      <Viewing forms={formContent} contacts={contactContent} />
    </Layout>
  );
}

export const query = graphql`
  query Project($slug: String!, $locale: String!) {
    page: wpSingleCottage(slug: { eq: $slug }, locale: {locale: {eq: $locale}}) {
      seo {
        title
        metaDesc
        breadcrumbs {
          text
          url
        }
      }
      title
      uri
      slug
      locale {
        locale
      }
      cottagesTerms {
        nodes {
          name
          slug
        }
      }
      singleProjectContent {
        aboutCottageText
        aboutCottageTitle
        aboutPriceText
        aboutPriceTitle
        cottageNumberOfFloors
        cottageNumberOfRooms
        cottagePlotArea
        cottagePrice
        cottageTotalArea
        firstFloor
        firstFloorLabel
        housePlanHeader
        masterPlanHeader
        toggleButton2d
        toggleButton3d
        secondFloor
        secondFloorLabel
        secondFloorItems {
          itemName
          itemValue
        }
        cottageGallery {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
            }
          }
        }
        firstFloorItems {
          itemName
          itemValue
        }
        housePlan2dImg {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: DOMINANT_COLOR
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
        housePlan3dImg {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: DOMINANT_COLOR
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
        masterPlanImg {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: DOMINANT_COLOR
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
        previewButton {
          icon
          text
          url
        }
        secondHousePlan2dImg {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: DOMINANT_COLOR
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
        secondHousePlan3dImg {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: DOMINANT_COLOR
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
      }
    }
    copyright: allWpRepeatableBlocks(
      filter: {slug: {eq: "copyrights"}}
    ) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(filter: {slug: {eq: "ugoda-korystuvacha"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(filter: {slug: {eq: "polityka-cookies"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    info: allWpRepeatableBlocks(filter: { slug: { eq: "purchase-info" } }) {
      nodes {
        blockPurchaseInfo {
          purchaseAdditionalTextInfo
          purchaseDocumentsHeader
          purchaseProcessHeader
          purchaseTermsHeader
          purchaseDocumentList {
            documentItemName
          }
          purchaseStepList {
            stepItemName
          }
          purchaseTermList {
            termItemDescription
            termItemTitle
          }
        }
        slug
        locale {
          locale
        }
      }
    }
    cottages: allWpRepeatableBlocks(filter: { slug: { eq: "cotteges" } }) {
      nodes {
        blockCotteges {
          cottegeLevels
          cottegeLevelsIcon
          cottegeLivingArea
          cottegeLivingAreaIcon
          cottegeTotalArea
          cottegeTotalAreaIcon
          cottegeTotalCars
          cottegeTotalCarsIcon
        }
        slug
        uri
        locale {
          locale
        }
      }
    }
    contacts: allWpRepeatableBlocks(
      filter: {
        slug: { eq: "kontaktna-informacziya" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        blockContacts {
          contactAddressText
          contactAddressTitle
          contactEmailAdressText
          contactEmailAdressTitle
          contactPhonesListTitle
          fieldGroupName
          scheduleListTitle
          contactPhonesList {
            phoneNumber
          }
          scheduleList {
            item
          }
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { slug: { eq: "formy" } }
    ) {
      nodes {
        locale {
          locale
        }
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          invalidMailMsg
          invalidPhoneMsg
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
  }
`;
